<template>
  <div class="my-border customlightgray elevation-0 usermain ma-0 pa-0">
    <v-stepper v-model="e1" class="elevation-0">
      <v-stepper-header class="gray elevation-0">
        <template v-for="n in steps" >
          <v-stepper-step
            :key="`${n.id}-step`"
            :complete="e1 > n.id"
            :step="n.id"
            :editable="false"
            color="#046e8b"
          >{{ $t(n.title) }}</v-stepper-step>
          <v-divider v-if="n.id !== steps" :key="n.id"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items class="white">
        <v-stepper-content key="1-content" step="1">
          <v-card class="mb-12 elevation-0">
            <v-autocomplete
              v-model="newItem.request_type"
              :items="types"
              @change="generateReqCode()"
              item-value="id"
              item-text="description_en"
              :placeholder="$t('requesttype')"
              class="ma-0 pa-0"
              outlined
              dense
              rounded
              filled
              hide-details
            ></v-autocomplete>
          </v-card>
          <v-btn depressed @click="$router.push({ name: 'dashboard' });">{{$t("cancel")}}</v-btn>
          <v-btn
            :disabled="!newItem.request_type"
            depressed
            class="ma-2 custom-color-accent"
            @click="nextStep(1)"
          >{{$t("next")}}</v-btn>
        </v-stepper-content>
        <v-stepper-content key="2-content" step="2">
          <v-card class="mb-2 elevation-0">
            <requestors @selectAddress="selectAddress" :addressesFiltered="addressesFiltered"></requestors>
          </v-card>
          <v-btn depressed @click="lastStep(2)">{{$t("back")}}</v-btn>
        </v-stepper-content>
        <v-stepper-content key="3-content" step="3">
          
          <v-card class="mb-2 elevation-0" v-if="newItem.request_type">
            <reqDetails :request="newItem" :requestor="requestor" :type="newItem.request_type"></reqDetails>
          </v-card>
          <v-btn depressed @click="lastStep(3)">{{$t("back")}}</v-btn>
          <v-btn depressed class="mx-sm-1 my-1 my-sm-0 custom-color-accent" @click="save()">{{$t("addrequestandcontinue")}}</v-btn>
          <v-btn depressed class="custom-color-accent" @click="saveAndClose()">{{$t("addrequestandclose")}}</v-btn>
        </v-stepper-content>
        <v-stepper-content key="4-content" step="4">
          <v-card class="mb-2 elevation-0 my-border">
            <requestHeader v-if="newItem.id" :request="currentRequest"></requestHeader>
            <v-divider></v-divider>
            <attachmentTable class="pa-3" v-if="newItem.id" :request="currentRequest" :attachments="getAttachments()"></attachmentTable>
          </v-card>
          <v-btn disabled depressed @click="lastStep(5)">{{$t("back")}}</v-btn>
          <v-btn depressed class="ma-2 custom-color-accent" @click="nextStep(4)">{{$t("next")}}</v-btn>
        </v-stepper-content>
        <v-stepper-content key="5-content" step="5">
          <v-card class="mb-2 elevation-0 my-border">
            <requestHeader v-if="newItem.id" :request="currentRequest"></requestHeader>
            <v-divider></v-divider>
            <feesTable class="pa-3" v-if="newItem.id" :request="currentRequest"></feesTable>
          </v-card>

          <v-btn depressed @click="lastStep(5)">{{$t("back")}}</v-btn>
          <v-btn @click="finishAdd()" depressed class="ma-2 custom-color-accent">{{$t("finish")}}</v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import reqDetails from "../components/request/AddReqDetails";
import attachmentTable from "@/components/request/AttachmentTable";
import feesTable from "../components/request/FeesTable";
import requestors from "../components/request/AddressTable";
import { mapState, mapActions, mapGetters } from "vuex";
import requestHeader from "../components/request/RequestHeader";

import moment from "moment";
export default {
  components: {
    reqDetails,
    attachmentTable,
    feesTable,
    requestors,
    requestHeader
  },
  data() {
    return {
      addressFilteres:{},
      e1: 1,
      steps: [
        { id: 1, title: "requesttype" },
        { id: 2, title: "requestor" },
        { id: 3, title: "details" },
        { id: 4, title: "attachments" },
        { id: 5, title: "fees" }
      ],
      newItem: {
        RequestStatus: "New",
        StatusDate: moment().format("YYYY-MM-DD"),
        DateDue: null,
        org_id: "1",
        request_type: null,
        request_type_desc:null,
        reqCode: "",
        RequestorId: null,
        RequestLanguagePref: "fr",
        SecurityClass: null,
        ClauseSet: null,
        DateReceived: moment().format("YYYY-MM-DD"),
        DateOnRequest: moment().format("YYYY-MM-DD"),
        DateInfoComplete: moment().format("YYYY-MM-DD"),
        TargetDays: null,
        SourceId: null
      },
      requestor: {}
    };
  },
  computed: {
    ...mapState({
      types: "types"
    }),
    ...mapState("request", {
      currentRequest: "requestItem"
    }),
     ...mapGetters('address', {
      'addressesFiltered':'addressesFiltered'
    }),
     ...mapState('address', {
      'storeAddressFilters':'filter'
    }),
    ...mapGetters('attachment', {
      getAttachment: 'getAttachments'
    })
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    addressesFiltered: {
      handler(){
        Object.assign(this.addressFilteres, this.storeAddressFilters);
      }, deep: true 
    }
  },
  async created() {
    Object.assign(this.addressFilteres, this.storeAddressFilters);
    await this.filterAddresses(this.addressFilteres);
    await this.clearAddressesFilter();
    await this.retriveAttachments();
    await this.retrieveAddresses();
    await this.retriveTypes();
    await this.retriveUsers();
    await this.retriveRequests();
  },
  methods: {
    getAttachments (){
      return this.getAttachment(this.$route.params.request_id);
    },
    save() {
      this.addRequest(this.newItem).then(
        response => {
          this.newItem = response.data;
          this.nextStep(3);
          this.increaseSerial(this.newItem.request_type);
        },
        () => {
          this.$root.$emit("callAlert", {
            text: this.$t("Something went wrong"),
            type: "warning",
            alert: true
          });
        }
      );
    },saveAndClose() {
      this.addRequest(this.newItem).then(
        response => {
          this.newItem = response.data;
         this.increaseSerial(this.newItem.request_type);
          this.finishAdd();
        },
        () => {
          this.$root.$emit("callAlert", {
            text:this.$t("Something went wrong"),
            type: "warning",
            alert: true
          });
        }
      );
    },
    nextStep(n) {
      if (n === this.steps) {
        return;
      } else {
        this.e1 = n + 1;
      }
    },
    lastStep(n) {
      if (n === 1) {
        this.e1 = 1;
      } else {
        this.e1 = n - 1;
      }
    },
    selectAddress(item) {
      this.newItem.RequestorId = item.id;
      this.requestor = item;
      this.nextStep(2);
    },
    generateReqCode() {
      let type = this.types.filter(item => item.id == this.newItem.request_type)[0];
      this.newItem.request_type_desc=type.description_en;
      var zeros = "";
      let lastId = type.last_used_serial_number + 1;
      for (var i = lastId.toString().length; i < 4; i++) {
        zeros = "0" + zeros;
      }
      this.newItem.reqCode = `${type.request_type}-${moment().year()}-${zeros +
        lastId}`;
      this.reqCode = `${type.request_type}-${moment().year()}-${zeros +
        lastId}`;
    },
    finishAdd() {
        this.$router.push({name: 'requests',params: { id: this.newItem.id }})
    },
    ...mapActions(["retriveTypes", "increaseSerial", "retriveUsers"]),
    ...mapActions("request", ["addRequest","retriveRequests"]),
    ...mapActions('address',['retrieveAddresses','filterAddresses','clearAddressesFilter']),
    ...mapActions('attachment',['retriveAttachments'])
  }
};
</script>
<style >
.v-stepper__step__step.primary{
  background-color: #14535c !important ;color:white !important;
}
</style>

