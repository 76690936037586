<template>
  <v-expansion-panels v-model="panel" multiple class="text-break">

      <v-expansion-panel  class="elevation-0 pa-0 ma-0 white">
        <div class="elevation-0 pa-0 ma-0  my-border ">
          <v-expansion-panel-header>{{ $t('Request Information') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="customlightgray pa-3 ma-0 my-border">
                <v-row class="pa-0 ma-0">
                  <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled  ">{{ $t('requestid') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                          <v-input class="ma-0 mr-0 mr-md-6 " dense disabled>
                            {{ request.reqCode }}
                          </v-input>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0 my-1">
                      <v-col cols="6" class="pa-0 ma-0" >
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('atipofficer') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                          <v-autocomplete
                            :items="users"
                            v-model="request.ATIPOfficerId"
                            item-value="id"
                            item-text="full_name"
                            class="ma-0 mr-0 mr-md-6 pa-0 white"
                            outlined
                            dense
                            hide-details
                          ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0 my-1">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('atipmanager') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                          <v-autocomplete
                            :items="users"
                            v-model="request.Approver"
                            item-value="id"
                            item-text="full_name"
                            class="ma-0 mr-0 mr-md-6 pa-0 white"
                            outlined
                            dense
                            hide-details
                          ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                    <v-row class="pa-0 ma-0 my-1">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('datereceived') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                        <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          class="ma-0 pa-0"
                          hide-details
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              append-icon="mdi-calendar"
                              outlined
                              dense
                              v-model="request.DateReceived"
                              class="ma-0 mr-0 mr-md-6 pa-0 white"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker dark v-model="request.DateReceived" :max="nowDate"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0 my-1">
                      <v-col cols="6" class="pa-0 ma-0" >
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('dateonrequest') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                          <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          class="ma-0 pa-0"
                          hide-details
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              append-icon="mdi-calendar"
                              outlined
                              dense
                              v-model="request.DateOnRequest"
                              class="ma-0 mr-0 mr-md-6 pa-0 white"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker dark v-model="request.DateOnRequest" :max="nowDate"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0 my-1">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('datecompletereceived') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                          <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          class="ma-0 pa-0"
                          hide-details
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              append-icon="mdi-calendar"
                              outlined
                              dense
                              v-model="request.DateInfoComplete"
                              class="ma-0 mr-0 mr-md-6 pa-0 white"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker dark v-model="request.DateInfoComplete" :max="nowDate"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                    <v-row class="pa-0 ma-0 my-1">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('datedue') }}:</span>
                      </v-col>
                      <v-col v-if="!type.editable_due_date" cols="6" class="pa-0 ma-0 text--secondary  ">
                          <v-input class="ma-0 mr-0 mr-md-6 " dense disabled>
                            {{$t("to be calculated")}}
                          </v-input>
                      </v-col>
                      <v-col v-else cols="6" class="pa-0 ma-0 text--secondary  ">
                          <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          class="ma-0 pa-0"
                          hide-details
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              append-icon="mdi-calendar"
                              outlined
                              dense
                              v-model="request.DateDue"
                              class="ma-0 mr-0 mr-md-6 pa-0 white"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker dark v-model="request.DateDue" :min="nowDate"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0" >
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('status') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                          <v-input class="ma-0 mr-0 mr-md-6 " dense disabled>
                            {{ $t(request.RequestStatus) }}
                          </v-input>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('requestor') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                           <v-input class="ma-0 mr-0 mr-md-6 " dense disabled>
                            {{requestor.FirstName}} {{requestor.LastName}}
                          </v-input>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0 mb-1">
                      <v-col cols="2" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('XRef') }}:</span>
                      </v-col>
                      <v-col cols="10" class="pa-0 ma-0">
                        <v-autocomplete
                          v-model="request.XRef"
                          :items="requests"
                          item-value="reqCode"
                          item-text="reqCode"
                          class="ma-0 mr-0 mr-md-6 pa-0 white"
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" sm="4" md="2" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('summarytext') }}: </span>
                      </v-col>
                      <v-col cols="12" sm="12" md="10" class="pa-0 ma-0 text--secondary  ">
                       <v-textarea
                          v-model="request.Summary"
                          rows="2"
                          auto-grow
                          clearable
                          hide-details
                          dense
                          outlined
                          class="mr-0 mr-md-6 ma-0 pa-0 white"
                        ></v-textarea>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
        </div>
      </v-expansion-panel>


      <v-expansion-panel  class="elevation-0 pa-0 ma-0 white">
        <div class="elevation-0 pa-0 ma-0  my-border ">
          <v-expansion-panel-header>{{ $t('Requestor Information') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="customlightgray pa-3 ma-0 my-border">
                 <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                  <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('firstname') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.FirstName">
                            {{ requestor.FirstName }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('lastname') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.LastName">
                            {{ requestor.LastName }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('emailaddress') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.Email">
                            {{ requestor.Email }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>   
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('telephone') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.Phone1">
                            {{ requestor.Phone1 }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('cellphone') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.Phone2">
                            {{ requestor.Phone2 }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                  <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('streetnumber') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.AddrNum">
                            {{ requestor.AddrNum }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('street') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.Address">
                            {{ requestor.Address }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('city') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.City">
                            {{ requestor.City }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{$t("province")}}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.Province">
                            {{ requestor.Province }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('postalcode') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-uppercase">
                        <label v-if="requestor.PostalCode">
                            {{ requestor.PostalCode }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                  <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('country') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.Country">
                            {{ requestor.Country }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{$t("source")}}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.Source">
                            {{ requestor.Source }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('addresstype') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.AddressType">
                            {{ requestor.AddressType }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('correspondencemethod') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0">
                          <label>------</label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row> 
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('preferredlanguage') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="requestor.LangPref">
                            {{ requestor.LangPref=='en'?$t('english'):$t('french')}}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </div>
      </v-expansion-panel>


      <v-expansion-panel  class="elevation-0 pa-0 ma-0 white">
        <div class="elevation-0 pa-0 ma-0  my-border ">
            <v-expansion-panel-header>{{ $t('originaltext') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="customlightgray pa-3 ma-0 my-border">
                <v-row class="pa-0 ma-0">
                  <v-col class="d-flex pa-1" cols="12" sm="12">
                    <v-textarea
                      v-model="request.RequestText"
                      rows="2"
                      auto-grow
                      clearable
                      hide-details
                      dense
                      outlined
                      class="mr-0 ma-0 pa-0 white"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
        </div>
      </v-expansion-panel>


      <v-expansion-panel  class="elevation-0 pa-0 ma-0 white">
        <div class="elevation-0 pa-0 ma-0  my-border">
            <v-expansion-panel-header>{{ $t('Additional Information') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="customlightgray pa-3 ma-0 my-border">
                <v-row class="pa-0 ma-0">
                  <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('requesttype') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">{{request.request_type_desc}}</v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('securityclass') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0">
                        <v-autocomplete
                          v-model="request.SecurityClass"
                          :items="getLookup( 'Security' )"
                          class="ma-0 mr-0 mr-md-6 pa-0 white"
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('complexity') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0">
                        <v-autocomplete
                          v-model="request.Complexity"
                          :items="getLookup( 'Complexity' )"
                          class="ma-0 mr-0 mr-md-6 pa-0 white"
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('category') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0">
                        <v-autocomplete
                          v-model="request.Category"
                        :items="getLookup( 'Category' )" 
                          class="ma-0 mr-0 mr-md-6 pa-0 white"
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('clauseset') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0">
                        <v-autocomplete
                          v-model="request.ClauseSet"
                          :items="getLookup( 'ClauseSet' )"
                          class="ma-0 mr-0 mr-md-6 pa-0 white"
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('accessmethod') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0">
                        <v-autocomplete
                          v-model="request.RequestCorrespondPref"
                          :items="getLookup( 'AccMethod' )" 
                          class="ma-0 mr-0 mr-md-6 pa-0 white"
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
        </div>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState } from "vuex";
import moment from 'moment';
export default {
  props: ["request", "requestor","type"],
  data() {
    return {
      nowDate: new moment().toISOString(),
      panel: [0, 1, 2,3],
    };
  },
  created(){
    if(this.type.clause_set)
    this.request.ClauseSet=this.type.clause_set
    if(this.type.security_class)
    this.request.SecurityClass=this.type.security_class
  },
  computed: {
    ...mapState({
      users: "users"
    }),
    ...mapState('request',{
      'requests':'requests'
    }),
    ...mapState("configuration", {
      configurations: "configurations"
    })
  },
  methods: {
    getlookupvalue(lookupname, val) {
      return this.configurations
        .filter(
          filter => filter.LookUpName === lookupname && filter.LookUpKey == val
        )
        .map(item => {
          let status = "";
          status =
            localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          return status;
        })[0];
    },
    getLookup(column) {
      return this.configurations
        .filter(filter => filter.LookUpName === column)
        .map(item => {
          let arr = [];
          arr["text"] =
            localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    }
  }
};
</script>